<template>
  <el-drawer
      :with-header="false"
      v-model="table"
      direction="ltr"
      size="50%"
  >
    <el-table
        :data="resp.schoolList"
        border
        max-height="100vh"
        style="width: 100%"
    >
      <el-table-column prop="name" label="学校" :fixed="true"></el-table-column>
      <el-table-column prop="grade" label="年级" width=80></el-table-column>
      <el-table-column prop="nature" label="类别" width=80></el-table-column>
      <el-table-column prop="level" label="梯队" width=80></el-table-column>
      <el-table-column prop="addr" label="地址"></el-table-column>
      <el-table-column prop="detail" label="详情" width=100>
        <template #default="scope">
          <a style="color: #409eff" cursor="pointer" @click="openDetail(scope.row.detail)">查看详情</a>
        </template>
      </el-table-column>

    </el-table>
  </el-drawer>
  <el-drawer
      :with-header="false"
      v-model="houseSearch"
      @close="getBubble"
      direction="ttb"
      size="60%"
  >
    <template #default>
      <el-text tag="b" size="large">房源检索</el-text>
      <el-form style="text-align: left; margin-top: 5px; margin-left: 120px" :inline="false" :model="queryValue">
        <el-form-item label="价格（万）:" size="small">
          <el-checkbox-group v-model="queryValue.price">
            <el-checkbox v-for="s in selections.prices" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
          <el-space>
            <el-input type="number" style="width:120px; margin-left: 20px" v-model="queryValue.bp"></el-input>
            <el-text size="large" style="margin-left: 10px; margin-right: 10px"> -</el-text>
            <el-input type="number" style="width:120px" v-model="queryValue.ep"></el-input>
            <el-text size="large"> （万）</el-text>
          </el-space>

        </el-form-item>
        <el-form-item label="房型:" size="small">
          <el-checkbox-group v-model="queryValue.layout">
            <el-checkbox v-for="s in selections.layouts" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="面积（m²）:" size="small">
          <el-checkbox-group v-model="queryValue.extent">
            <el-checkbox v-for="s in selections.extents" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
          <el-space>
            <el-input type="number" style="width:120px; margin-left: 20px" v-model="queryValue.ba"></el-input>
            <el-text size="large" style="margin-left: 10px; margin-right: 10px"> -</el-text>
            <el-input type="number" style="width:120px" v-model="queryValue.ea"></el-input>
            <el-text size="large"> （m²）</el-text>
          </el-space>
        </el-form-item>
        <el-form-item label="房源特色:" size="small">
          <el-checkbox-group v-model="queryValue.feature">
            <el-checkbox v-for="s in selections.features" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="朝向:" size="small">
          <el-checkbox-group v-model="queryValue.face">
            <el-checkbox v-for="s in selections.faces" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="楼层:" size="small">
          <el-checkbox-group v-model="queryValue.floor">
            <el-checkbox v-for="s in selections.floors" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="楼龄:" size="small">
          <el-checkbox-group v-model="queryValue.age">
            <el-checkbox v-for="s in selections.ages" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="装修:" size="small">
          <el-checkbox-group v-model="queryValue.decoration">
            <el-checkbox v-for="s in selections.decorations" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="用途:" size="small">
          <el-checkbox-group v-model="queryValue.purpose">
            <el-checkbox v-for="s in selections.purposes" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="电梯:" size="small">
          <el-checkbox-group v-model="queryValue.elevator">
            <el-checkbox v-for="s in selections.elevators" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="供暖:" size="small">
          <el-radio-group v-model="queryValue.heating">
            <el-radio v-for="s in selections.heatings" :key="s.code" :label="s.code">{{ s.value }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="权属:" size="small">
          <el-radio-group v-model="queryValue.owner">
            <el-radio v-for="s in selections.owners" :key="s.code" :label="s.code">{{ s.value }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="类别:" size="small">
          <el-checkbox-group v-model="queryValue.type">
            <el-checkbox v-for="s in selections.types" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

      </el-form>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="clear" type="primary">清空筛选</el-button>
      </div>
    </template>
  </el-drawer>
  <el-drawer
      :with-header="false"
      v-model="schoolSearch"
      direction="ttb"
      size="25%"
  >
    <template #default>
      <el-text tag="b" size="large">学校检索</el-text>
      <el-form style="text-align: left; margin-top: 5px; margin-left: 120px" :inline="true" :model="queryValue">
        <el-form-item label="年级:" size="small">
          <el-checkbox-group v-model="queryValue.grade">
            <el-checkbox v-for="s in selections.grades" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="类型:" size="small">
          <el-checkbox-group v-model="queryValue.nature">
            <el-checkbox v-for="s in selections.natures" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>

        </el-form-item>

        <el-form-item label="梯队:" size="small">
          <el-checkbox-group v-model="queryValue.level">
            <el-checkbox v-for="s in selections.levels" :key="s.code" :label="s.code">{{ s.value }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="区域:" size="small">
          <el-select
              v-model="queryValue.area"
              allow-create
              default-first-option
              filterable
              collapse-tags
              clearable
              collapse-tags-tooltip
              :max-collapse-tags="1"
          >
            <el-option
                v-for="k in selections.areas"
                :key="k.code"
                :label="k.value"
                :value="k.code"
            />

          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="query" icon="Search" type="primary" :loading="queryValue.loading">查询</el-button>
      </div>
    </template>
  </el-drawer>
  <el-drawer
      :with-header="false"
      v-model="list"
      direction="ltr"
      size="30%"
  >
    <!--    bubble 头-->
    <div @click="openDetail(resp.bubbleDetail.bubble.actionUrl)">
      <el-row justify="space-between">
        <el-col :span="18">
          <el-text size="large" tag="b"> {{ resp.bubbleDetail.bubble.communityName }}</el-text>
        </el-col>
        <el-col :span="6">
          <el-text> 参考均价</el-text>
        </el-col>
      </el-row>
      <el-row justify="space-between" style="margin-top: 12px;">
        <el-col :span="18">
          <el-text type="info" size="small"> {{ resp.bubbleDetail.bubble.communityDesc }}</el-text>
        </el-col>
        <el-col :span="6">
          <el-text type="danger"> {{ resp.bubbleDetail.bubble.avgUnitPriceDesc }}</el-text>
        </el-col>
      </el-row>
    </div>

    <el-divider/>
    <!--    list-->
    <div v-for="k in resp.bubbleDetail.houseList" :key="k.title">
      <el-space :size="4" @click="openDetail(k.actionUrl)">
        <el-image style="width: 100px;height: 100px" :src="imageProxy(k.coverPic)"/>
        <div>
          <el-text size="large" tag="b" line-clamp="2">{{k.title}}</el-text>
          <el-text size="small" line-clamp="1">{{k.desc}}</el-text>
          <el-space :size="4" wrap>
            <el-tag v-for="t in k.tags" :key="t.desc" size="small" >{{t.desc}}</el-tag>
          </el-space>
          <el-row >
            <el-col :span="9">
              <el-text size="large" type="danger">{{k.priceStr}}</el-text>

            </el-col>
            <el-col :span="12">
              <el-text size="small" type="info" >{{k.unitPriceStr}}</el-text>
            </el-col>
          </el-row>
        </div>
      </el-space>
    <el-divider/>
    </div>


  </el-drawer>

  <baidu-map style="width: 100% ;height: 100vh;" :center="map.center" :zoom="map.zoom"
             :min-zoom="11"
             :scrollWheelZoom="true" @ready="ready">

    <bm-control>
      <el-card>
        <el-button @click="table = true">学校列表</el-button>
        <el-button @click="schoolSearch = true">学校检索</el-button>
        <el-button @click="houseSearch = true">房源检索</el-button>
        <el-button @click="list = true">房源列表</el-button>
      </el-card>
    </bm-control>

    <bm-marker v-for="k in resp.schoolAddr" :key="k.name" :position="{lng: k.lng,lat: k.lat}" :dragging="false"
               @click="clickPoint(k)">
      <bm-label :content="concatSchoolAlias(k)" :labelStyle="{color: 'red', fontSize : '11px'}"
                :offset="{width: -35, height: 30}"/>
    </bm-marker>

    <bm-label v-for="k in resp.bubbleList" :key="k.name" @mouseover="labelOver(k)" @mouseout="labelOut(k)"
              :position="{lng: k.longitude,lat: k.latitude}" :content="concatBubblePrice(k)" :labelStyle="labelStyle(k)"
              @click="clickLabel(k)"
    />
    <bm-polygon v-if="map.polygon.show" :path="map.polygon.path" stroke-color="blue" :stroke-opacity="0.5"
                :stroke-weight="2"></bm-polygon>
  </baidu-map>

</template>

<script>
import {ElMessage} from 'element-plus'
import axios from 'axios'
import urlencode from "urlencode";

import {BaiduMap, BmControl, BmLabel, BmMarker, BmPolygon} from "vue-baidu-map-3x";

export default {
  name: 'App',
  components: {BmPolygon, BmControl, BmLabel, BmMarker, BaiduMap},
  mounted() {

  },
  methods: {
    imageProxy(i){
      return "https://api.zhjsmy.com/school/imageProxy?direct="+urlencode(i);
    },
    appendColor(k){
      return '#'+k
    },
    clickLabel(k) {
      console.log(k)
      var that = this
      let condition = this.getCondition()
      axios.get('https://api.zhjsmy.com/school/houselist' +
          '?type=' + k.imageType +
          '&resBlockId=' + k.id+
          '&condition='+condition
      ).then(function (response) {
        console.log(response)
        that.resp.bubbleDetail.bubble = response.data.bubble
        that.resp.bubbleDetail.houseList = response.data.list
        that.list = true
      }).catch(function (error) {
        ElMessage({showClose: true, message: error, type: 'error'})
      })
    },
    labelStyle(k) {
      if (k.over) {
        return {
          backgroundColor: 'rgba(100, 37, 217, 0.8)',
          borderRadius: '10px', // 设置圆角半径，可根据需求调整
          padding: '5px 10px',
          border: '1px solid #ccc',
          fontSize: '14px',
          color: '#333'
        }
      }
      return {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '10px', // 设置圆角半径，可根据需求调整
        padding: '5px 10px',
        border: '1px solid #ccc',
        fontSize: '14px',
        color: '#333'
      }
    },
    labelOver(k) {
      console.log("labelOver", k)
      k.over = true
      var that = this
      that.map.polygon.path = this.splitBorder(k)
      that.map.polygon.show = true
      console.log("drawBorder", that.polygon)
    },
    labelOut(k) {
      var that = this
      k.over = false
      that.map.polygon.show = false
      that.map.polygon.path = []
    },
    splitBorder(k) {
      let points = []
      k.border.split(";").forEach((item) => {
        let latlng = item.split(",")
        points.push({lng: latlng[0], lat: latlng[1]})
      })
      return points
    },
    clear() {
      var that = this
      that.queryValue.price = []
      that.queryValue.layout = []
      that.queryValue.extent = []
      that.queryValue.feature = []
      that.queryValue.face = []
      that.queryValue.floor = []
      that.queryValue.age = []
      that.queryValue.decoration = []
      that.queryValue.purpose = []
      that.queryValue.elevator = []
      that.queryValue.heating = ''
      that.queryValue.owner = ''
      that.queryValue.type = []
      that.queryValue.bp = ''
      that.queryValue.ep = ''
      that.queryValue.ba = ''
      that.queryValue.ea = ''
    },
    ready(e) {
      e.map.addEventListener('zoomend', () => {
        this.mapChange(e.map)
      });

      // 监听地图拖拽结束事件
      e.map.addEventListener('dragend', () => {
        this.mapChange(e.map)
      });

      e.map.addEventListener('resize', () => {
        this.mapChange(e.map)
      });
      this.mapChange(e.map)
    },
    getCondition(){
      var that = this
      let condition = that.queryValue.price.join('') +
          that.queryValue.layout.join('') +
          that.queryValue.extent.join('') +
          that.queryValue.feature.join('') +
          that.queryValue.face.join('') +
          that.queryValue.floor.join('') +
          that.queryValue.age.join('') +
          that.queryValue.decoration.join('') +
          that.queryValue.purpose.join('') +
          that.queryValue.elevator.join('') +
          that.queryValue.heating +
          that.queryValue.owner +
          that.queryValue.type.join('')
      if (that.queryValue.bp) {
        condition = condition.concat('bp' + that.queryValue.bp)
      }
      if (that.queryValue.ep) {
        condition = condition.concat('ep' + that.queryValue.ep)
      }
      if (that.queryValue.ba) {
        condition = condition.concat('ba' + that.queryValue.ba)
      }
      if (that.queryValue.ea) {
        condition = condition.concat('ea' + that.queryValue.ea)
      }
      return condition
    },
    mapChange(map) {
      var that = this
      let bounds = map.getBounds()
      //东北点 lat、lng最大
      let ne = bounds.getNorthEast()
      //西南点 lat、lng最小
      let sw = bounds.getSouthWest()
      that.map.zoom = map.getZoom()
      that.map.minLat = sw.lat
      that.map.maxLat = ne.lat
      that.map.minLng = sw.lng
      that.map.maxLng = ne.lng
      console.log('mapchange',that.map)
      this.getBubble()
    },
    getBubble(){
      var that = this
      that.resp.bubbleList = []
      that.resp.houseList = []
      if (that.map.zoom > 15) {
        let condition = this.getCondition()
        //查看当前位置房源

        axios.get('https://api.zhjsmy.com/school/bubblelist' +
            '?cityId=310000' +
            '&dataSource=ESF' +
            '&condition=' + condition +
            '&id=' +
            '&groupType=community' +
            '&maxLatitude=' + that.map.maxLat +
            '&minLatitude=' + that.map.minLat +
            '&maxLongitude=' + that.map.maxLng +
            '&minLongitude=' + that.map.minLng
        ).then(function (response) {
          console.log(response)
          that.resp.bubbleList = response.data.list
        }).catch(function (error) {
          ElMessage({showClose: true, message: error, type: 'error'})
        })
      }

    },
    concatSchoolAlias(k) {
      return k.name + k.alias
    },
    concatBubblePrice(k) {
      return k.name + ' | ' + k.priceStr
    },
    openDetail(url) {
      console.log(url)
      window.open(url)
    },
    clickPoint(data) {
      var that = this
      that.map.center = {lng: data.lng, lat: data.lat}
      that.map.zoom = 17

      console.log(data)
    },
    query() {
      var that = this
      if (that.queryValue.area.length === 0) {
        ElMessage({showClose: true, message: '请选择要查询你那个区域的学校。', type: 'error'})
        return
      }
      console.log(that.queryValue)
      that.queryValue.loading = true
      axios.get('https://api.zhjsmy.com/school/query' +
          '?grade=' + that.queryValue.grade.join(',') +
          '&area=' + that.queryValue.area +
          '&level=' + that.queryValue.level.join(',') +
          '&nature=' + that.queryValue.nature.join(',')
      ).then(function (response) {
        console.log(response)
        that.resp.schoolList = response.data.response.list
        that.resp.schoolAddr = response.data.response.addr
        that.map.center = response.data.response.map.center
        that.map.zoom = response.data.response.map.zoom
        that.schoolSearch = false
        that.table = true
      }).catch(function (error) {
        ElMessage({showClose: true, message: error, type: 'error'})
      })
      that.queryValue.loading = false
    }

  },
  data() {
    return {
      table: false,
      houseSearch: false,
      schoolSearch: false,
      list: false,
      map: {
        zoom: 12,
        center: {
          lng: 121.506377,
          lat: 31.245105
        },
        polygon: {
          show: false,
          path: []
        },
        minLat:0,
        maxLat:0,
        minLng:0,
        maxLng:0
      },
      resp: {
        schoolList: [],
        schoolAddr: [],
        bubbleList: [],
        bubbleDetail: {
          houseList: [],
          bubble: {
            communityName: '',
            communityDesc: '',
            avgUnitPriceDesc: '',
            actionUrl: ''
          }
        }
      },
      selections: {
        areas: [
          {code: 2, value: '浦东'},
          {code: 3, value: '徐汇'},
          {code: 4, value: '闵行'},
          {code: 5, value: '杨浦'},
          {code: 6, value: '虹口'},
          {code: 7, value: '长宁'},
          {code: 8, value: '宝山'},
          {code: 9, value: '静安'},
          {code: 10, value: '黄浦'},
          {code: 11, value: '普陀'}
        ],
        grades: [
          {code: 4, value: '小学'},
          {code: 5, value: '初中'}
        ],
        natures: [
          {code: 1, value: '民办'},
          {code: 2, value: '公办'}
        ],
        levels: [
          {code: 1, value: '一梯队'},
          {code: 2, value: '二梯队'},
          {code: 3, value: '三梯队'},
        ],
        prices: [
          {code: 'p1', value: '200以下'},
          {code: 'p2', value: '200-300'},
          {code: 'p3', value: '300-400'},
          {code: 'p4', value: '400-500'},
          {code: 'p5', value: '500-800'},
          {code: 'p6', value: '800-1000'},
          {code: 'p7', value: '1000以上'}
        ],
        layouts: [
          {code: 'l1', value: '一室'},
          {code: 'l2', value: '二室'},
          {code: 'l3', value: '三室'},
          {code: 'l4', value: '四室'},
          {code: 'l5', value: '五室'},
          {code: 'l6', value: '五室以上'},
        ],
        extents: [
          {code: 'a1', value: '50以下'},
          {code: 'a2', value: '50-70'},
          {code: 'a3', value: '70-90'},
          {code: 'a4', value: '90-110'},
          {code: 'a5', value: '110-130'},
          {code: 'a6', value: '130-150'},
          {code: 'a7', value: '150以上'}
        ],
        features: [
          {code: 'tt9', value: '必看好房'},
          {code: 'mw1', value: '满五年'},
          {code: 'ty1', value: '满两年'},
          {code: 'su1', value: '近地铁'},
          {code: 'tt8', value: 'VR房源'},
          {code: 'tt10', value: 'VR看装修'},
          {code: 'tt2', value: '7日新上'},
          {code: 'tt4 ', value: '随时看房'}

        ],
        faces: [
          {code: 'f5', value: '南北'},
          {code: 'f2', value: '朝南'},
          {code: 'f1', value: '朝东'},
          {code: 'f4', value: '朝北'},
          {code: 'f3', value: '朝西'},
        ],
        floors: [
          {code: 'lc1', value: '低楼层'},
          {code: 'lc2', value: '中楼层'},
          {code: 'lc3', value: '高楼层'},
        ],
        ages: [
          {code: 'y1', value: '5年以内'},
          {code: 'y2', value: '10年以内'},
          {code: 'y3', value: '15年以内'},
          {code: 'y4', value: '20年以内'},
          {code: 'y5', value: '20年以上'},
        ],
        decorations: [
          {code: 'de1', value: '精装修'},
          {code: 'de2', value: '普通装修'},
          {code: 'de3', value: '毛坯房'},
        ],
        purposes: [
          {code: 'sf1', value: '普通住宅'},
          {code: 'sf2', value: '商业类'},
          {code: 'sf3', value: '别墅'},
          {code: 'sf4', value: '四合院'},
          {code: 'sf6', value: '车位'},
          {code: 'sf5', value: '其他'},
        ],
        elevators: [
          {code: 'ie2', value: '有电梯'},
          {code: 'ie1', value: '无电梯'},
        ],
        heatings: [
          {code: 'hy1', value: '集体供暖'},
          {code: 'hy2', value: '自供暖'},
        ],
        owners: [
          {code: 'dp1', value: '商品房'},
          {code: 'dp2', value: '公房'},
          {code: 'dp3', value: '经适房'},
          {code: 'dp4', value: '其他'},
        ],
        types: [
          {code: 'bt1', value: '塔楼'},
          {code: 'bt2', value: '板楼'},
          {code: 'bt3', value: '板塔结合'},
        ]
      },
      queryValue: {
        grade: [],
        area: '',
        nature: [],
        level: [],
        loading: false,
        price: [],
        layout: [],
        extent: [],
        feature: [],
        face: [],
        floor: [],
        age: [],
        decoration: [],
        purpose: [],
        elevator: [],
        heating: '',
        owner: '',
        type: [],
        bp: '',
        ep: '',
        ba: '',
        ea: ''
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}


</style>
